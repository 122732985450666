(function ($) {
	$(window).load(function () {

		//Force click event in chrome
		document.querySelectorAll('a[href^="#"]').forEach(anchor => {
			console.log(anchor);
			anchor.addEventListener('click', function (e) {
				const targetId = this.getAttribute('href').substring(1);
				const targetElement = document.getElementById(targetId);
				if (targetElement) {
					e.preventDefault();
					targetElement.scrollIntoView({
						behavior: 'smooth',
						block: 'start'
					});
				}
			});
		});

		// owl-carousel
		$(".owl-carousel:not(.slider)").owlCarousel({
			items: 1,
			loop: true,
			lazyLoad: true,

			// autoplay
			autoplay: true,
			autoplayTimeout: 7000,

			// speed
			smartSpeed: 500,

			// animation
			animateIn: "fadeIn",
			animateOut: "fadeOut",

			// navigation
			nav: true,
			navText: [
				'<i class="far fa-chevron-left"></i>',
				'<i class="far fa-chevron-right"></i>',
			],
			dots: false,

			// drag
			mouseDrag: false,
		});

		// slider
		$(".slider.image-collection-photoalbum").owlCarousel({
			items: 1,
			margin: 10,
			loop: true,
			lazyLoad: true,

			// autoplay
			autoplay: false,
			autoplayTimeout: 7000,

			// speed
			smartSpeed: 500,

			// navigation
			nav: true,
			navText: [
				'<i class="far fa-chevron-left"></i>',
				'<i class="far fa-chevron-right"></i>',
			],
			dots: false,

			// drag
			mouseDrag: true,

			// responsive
			responsive: {
				0: {
					items: 1,
					margin: 15,
					stagePadding: 70,
				},
				578: {
					items: 2,
					margin: 15,
					stagePadding: 70,
				},
				768: {
					items: 3,
					margin: 15,
					stagePadding: 50,
				},
				992: {
					items: 3,
					margin: 20,
					stagePadding: 90,
				},
				1200: {
					items: 4,
					margin: 26,
					stagePadding: 125,
				},
			},
		});

		// Set Sliders Navigation position
		$('.owl-carousel.image-collection-photoalbum .owl-nav').css({
			right: ($(window).innerWidth() - $(".park-gallery-section .container").first().innerWidth()) / 2 + 15,
		});

		$(window).resize(function () {
			$('.owl-carousel.image-collection-photoalbum .owl-nav').css({
				right: ($(window).innerWidth() - $(".park-gallery-section .container").first().innerWidth()) / 2 + 15,
			});
		});

		// slider
		$(".slider.service-location-overview").owlCarousel({
			items: 1,
			margin: 15,
			loop: true,
			lazyLoad: true,

			// autoplay
			autoplay: false,
			autoplayTimeout: 1000,

			// speed
			smartSpeed: 500,

			// navigation
			nav: true,
			navText: [
				'<i class="far fa-chevron-left"></i>',
				'<i class="far fa-chevron-right"></i>',
			],
			dots: false,

			// drag
			mouseDrag: true,

			// responsive
			responsive: {
				0: {
					items: 1,
				},
				576: {
					items: 2,
				},
				768: {
					items: 2,
				},
				992: {
					items: 3,
				},
				1200: {
					items: 3,
				},
			},
		});

		// Reviews Slider
		$('.location-detail .reviews-overview').owlCarousel({
			items: 1,
			margin: 8,
			lazyLoad: true,
			
			// loop
			loop: false,
			rewind: true,

			// autoplay
			autoplay: true,
			autoplayTimeout: 7000,

			// speed
			smartSpeed: 500,

			// navigation
			nav: true,
			navText: [
				'<i class="far fa-chevron-left"></i>',
				'<i class="far fa-chevron-right"></i>',
			],
			dots: false,

			// drag
			mouseDrag: true,

			// responsive
			responsive: {
				0: {
					items: 1,
				},
				576: {
					items: 2,
				},
				768: {
					items: 2,
				},
				992: {
					items: 3,
				},
				1200: {
					items: 3,
				},
			},
		});

		$(".slider").owlCarousel({
			items: 1,
			margin: 15,
			loop: true,
			lazyLoad: true,

			// autoplay
			autoplay: true,
			autoplayTimeout: 7000,

			// speed
			smartSpeed: 500,

			// navigation
			nav: true,
			navText: [
				'<i class="far fa-chevron-left"></i>',
				'<i class="far fa-chevron-right"></i>',
			],
			dots: false,

			// drag
			mouseDrag: true,

			// responsive
			responsive: {
				0: {
					items: 1,
				},
				576: {
					items: 2,
				},
				768: {
					items: 2,
				},
				992: {
					items: 3,
				},
				1200: {
					items: 3,
				},
			},
		});

		// form-sent
		setTimeout(() => {
			var locationSearch = "" + document.location.search;
			if (
				(-1 === locationSearch.indexOf("form[sent]") &&
					-1 === decodeURIComponent(locationSearch).indexOf("form[sent]")) ||
				-1 === document.location.hash.indexOf("#form")
			) {
				return;
			}

			var $headerHeight = $(".header");
			var $message = $(".form-success-message");

			if (!$headerHeight.length || !$message.length) {
				return;
			}

			var currentScrollTop = $("html").scrollTop();
			var newScrollTop =
				$message.offset().top - $headerHeight.outerHeight() - 30;
			if (newScrollTop >= currentScrollTop) {
				return;
			}

			$("html, body").animate({
				scrollTop: $message.offset().top - $headerHeight.outerHeight() - 30,
			});
		}, 500);
	});

	$(document).ready(function () {

		// sticky
		//require("../../vendor/w3media/framework/assets/js/V2/body-sticky-class");
		$(window).scroll(function () {
			let $el = $("body").add(".header"),
				stickyStartPoint = $(".eyecatcher").outerHeight();

			if ($(window).scrollTop() > 0) {
				$el.addClass("sticky");
			} else {
				$el.removeClass("sticky");
			}
		});

		// make kasabon in smooth layout sticky
		require("../../vendor/w3media/framework/assets/js/V2/hc-sticky");
		$(".highlight-block-holder").hcSticky({
			stickTo: ".park-intro-section, .content-section",
			top: 170,
			responsive: {
				992: {
					disable: true,
				},
			},
		});

		setTimeout(() => {
			// wait to load booking-widget

			$(".location-detail .results-wrapper-with-sidebar .sidebar").hcSticky({
				top: 170,
				responsive: {
					992: {
						disable: true,
					},
				},
			});
			$(".results-wrapper-with-sidebar .sidebar").hcSticky({
				top: 100,
				responsive: {
					992: {
						disable: true,
					},
				},
			});
		}, 100);

		// fancybox
		$("a[data-fancybox]").fancybox({
			buttons: [
				//"zoom",
				//"share",
				//"slideShow",
				//"fullScreen",
				//"download",
				"thumbs",
				"close",
			],
		});

		// navbar-toggler
		$(document).on('click', '.navbar-toggler', function () {
			$('body').toggleClass('navbar-collapse-active');
		});

		// scroll-to-content
		$(".js-3w-scroll-to-content").on("click", function (event) {
			event.preventDefault();
			var identifier = $(this).attr("data-target");
			var $element = $(identifier);

			if ($element) {
				$("html, body").animate({ scrollTop: $element.offset().top }, 0);
			}
		});

		$('a[href^="#"]').on("click", function (e) {
			e.preventDefault();
			const identifier = $(this).attr("href");
			var $element = $(identifier);
			if ($element) {
				$("html, body").animate({ scrollTop: $element.offset().top - 140 }, 300);
			}
		});

		// faq fix
		let faqlinks = document.querySelectorAll(".faq-category a");
		faqlinks.forEach((link) => {
			if (link.id) {
				link.id = ""
				link.href = "#";
			}
		});

		// make location nav sticky
		function stickyLocationNav() {
			const $stickyNavbar = $("#location-tabs");

			if ($stickyNavbar.length === 0) return;

			const $stickyStopper = $("footer.footer");
			const $stickyHeader = $("header.header");

			// add active class to nav item on scrolling to same section
			const $navLinks = $stickyNavbar.find('li a'); // nav links
			const $sections = $navLinks.map(function () {
				return $($(this).attr('href')); // Section elements
			}).get();

			const generalNavbarHeight = $stickyNavbar.innerHeight();
			const stickyTop = $stickyNavbar.offset().top;

			$(window).on('scroll', function () {
				const stickyStopperPosition = $stickyStopper.offset().top;

				const stickOffset = $stickyHeader.innerHeight();
				const stopPoint = stickyStopperPosition - (generalNavbarHeight + stickOffset);
				const diff = stickyStopperPosition - generalNavbarHeight + 3;
				const windowTop = $(window).scrollTop();

				if (windowTop > stopPoint) {
					// On the footer
					$stickyNavbar.css({ position: 'absolute', top: diff });
					$stickyNavbar.addClass('sticky-navbar-end');
				} else if (stickyTop < windowTop + stickOffset) {
					// On scrolling
					$stickyNavbar.css({ position: 'fixed', top: stickOffset }).addClass('sticky-navbar');
					$stickyNavbar.removeClass('sticky-navbar-end');
				} else {
					// Default
					$stickyNavbar.css({ position: 'relative', top: 'initial' }).removeClass('sticky-navbar sticky-navbar-end');
				}

				// Update active nav link based on scroll position
				$sections.forEach(function ($section) {
					if ($section.length > 0) {
						const sectionTop = $section.offset().top;
						const changePoint = sectionTop - (generalNavbarHeight + stickOffset + 80);

						if (windowTop >= changePoint) {
							const $activeLink = $navLinks.filter(`[href="#${$section.attr('id')}"]`);
							$activeLink.parent().addClass('active').siblings().removeClass('active');
						}
					}
				});
			});
		}
		setTimeout(() => {
			stickyLocationNav();
		}, 1000);

		// prevent scrolling on #TommyBookingSupport page
		$(window).on("hashchange", function (e) {
			if ($("#TommyBookingSupport").length > 0) {
				e.preventDefault();

				// disable scrolling
				$('body').css('overflow', 'hidden');

				// set current scroll position
				$("html, body").animate({
					scrollTop: $("#TommyBookingSupport").offset().top - 120,
				});

				// enable scrolling
				setTimeout(() => {
					$('body').css('overflow', 'auto');
				}, 10)
			}
		});

		// menu toggle on sub menu close
		$('.menu .nav-item').on('hide.bs.dropdown', function () {
			if ($(window).innerWidth() < 1200) return;

			setTimeout(() => {
				if (!$(this).siblings('.dropdown').hasClass('show')) {
					$(".header").removeClass('header-active');
					$('.menu').css('padding-bottom', '');
				}
			}, 10);
		});

		// menu toggle
		$('.menu .nav-item .nav-link').on('click', function (e) {

			if ($(window).innerWidth() < 1200) return;

			const $this = $(this);

			if (!$this.parent('.nav-item').hasClass('show')) {

				$(".header").addClass('header-active');

				// get dropdown menu height
				setTimeout(() => {
					const menuHeight = $this.next('.dropdown-menu').outerHeight();
					const cssTopProp = parseInt($this.next('.dropdown-menu').css('top'), 10) / 2;

					$('.menu').css('padding-bottom', menuHeight + cssTopProp + 'px');
				}, 10);

			} else {
				$(".header").removeClass('header-active');
				$('.menu').css('padding-bottom', '');
			}
		});

		$('.toggle-collapse').on('click', function (e) {
			e.preventDefault();
			e.stopPropagation();

			var $collapse = $(this).parent('.collapsable-content');

			$collapse.toggleClass('active');

			if ($collapse.hasClass('active')) {
				$(this).text($(this).data('text-read-less'));
			} else {
				$(this).text($(this).data('text-read-more'));
			}
		});

		$('#show-google-maps').on('click', function (e) {
			$('.layers-map-placeholder').hide();
			$('.layer-maps').show();

			var mapProp = {
				center: new google.maps.LatLng($('#googleMap').data('latitude'), $('#googleMap').data('longitude')),
				zoom: 12,
			};
			var map = new google.maps.Map(document.getElementById("googleMap"), mapProp);

			const orangeMarkerIcon = {
				url: "https://maps.google.com/mapfiles/ms/icons/orange.png", // URL for an orange marker
				scaledSize: new google.maps.Size(32, 32), // Adjust size if necessary
			};

			var marker = new google.maps.Marker({
				position: mapProp.center,
				map: map,
				icon: orangeMarkerIcon,
			});
		});

		// open share icons
		$(document).on("click", ".share-accommodation > a", function (e) {
			$(this).next(".platforms-list").toggleClass("open");
		});

		//
		if ($('#location-tabs .tabs-list').length > 0) {
			$('#location-tabs .tabs').addClass('scrollable to-left');
		}
		$('#location-tabs .tabs-list').on('scroll', function () {
			const navItems = $(this).children();
			const navWidth = $(this).width();
			let totalWidth = 0;
			navItems.each(function (i) {
				totalWidth += parseInt(Math.ceil($(this).outerWidth(true)), 10);
			});
			const scrollWidth = totalWidth;

			// add classes
			$(this).parent().addClass('scrollable');
			if (Math.ceil($(this).scrollLeft()) === 0) {
				$(this).parent().addClass('to-left');
				$(this).parent().removeClass('to-right');
			} else {
				$(this).parent().addClass('to-right to-left');
			}

			if (Math.ceil(($(this).scrollLeft() + navWidth) + 1) >= scrollWidth) {
				$(this).parent().removeClass('to-left');
				$(this).parent().addClass('to-right');
			}
		});

	});
})(jQuery);
